import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import headerImage from '../../logo-kurpik.jpg'; // ścieżka do obrazka w folderze src

const backgroundImageUrl = `url(${headerImage})`; // Link do twojego obrazka

const HomeHeader: React.FC = () => {
    const style: SxProps<Theme> = {
        position: 'relative', // Pozycjonowanie względne, aby tekst był na obrazku
        color: 'black', // Biały tekst
        backgroundImage: backgroundImageUrl, // Ustawienie obrazka jako tła
        backgroundSize: 'cover', // Zakrycie całego tła
        backgroundPosition: 'center', // Wycentrowanie obrazka
        height: 300, // Wysokość nagłówka
        display: 'flex', // Używamy Flexbox do centrowania tekstu
        flexDirection: 'column', // Używamy Flexbox do centrowania tekstu
        alignItems: 'center', // Wycentrowanie tekstu w pionie
        justifyContent: 'center', // Wycentrowanie tekstu w poziomie
        margin: '0.5rem', // Marginesy
    };

    return (
        <Paper sx={style}>
            <Typography
                variant="h3"
                fontWeight="700"
                sx={{
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)', // x-offset | y-offset | blur-radius | color
                }}
            >
                eKurpik
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)', // x-offset | y-offset | blur-radius | color
                }}
            >
                Portal informacyjny
                <br />
                gmina Myszyniec
            </Typography>
        </Paper>
    );
};

export default HomeHeader;