import axios, {AxiosResponse} from "axios";
import {GetArticleByIdResponse, GetArticleListResponse} from "./types";
import {backendUrl} from "../Global/globalValues";

export const fetchArticleShortDtos = (setArticleShortDtos: any, page: number, setTotalPage: any, searchValue: string) => {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    if (searchValue !== "") {
        params.append('search', searchValue);
    }
    axios.get<GetArticleListResponse>("/article", {params: params, baseURL: backendUrl}).then((res: AxiosResponse) => {
        setArticleShortDtos(res.data.content);
        setTotalPage(res.data.totalPages);
    });
}

export const fetchArticleDto = (id: string, setArticleDto: any) => {
    axios.get<GetArticleByIdResponse>("/article/" + id, {baseURL: backendUrl}).then((res: AxiosResponse) => {
        setArticleDto(res.data);
    });
}

