import React, {useEffect, useState} from "react";
import {ArticleShortDto} from "../types";
import ArticleShort from "./ArticleShort";
import { fetchArticleShortDtos } from "../api";
import {Box, Pagination} from "@mui/material";
import HomeHeader from "./HomeHeader";

const ArticleList = (props: {searchValue: string, setSearchValue: (value: string) => void, refreshArticles: boolean, setRefreshArticles: (value: boolean) => void}) => {
    const [articleShortDtos, setArticleShortDtos] = useState<ArticleShortDto[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value-1);
    };

    useEffect(() => {
        fetchArticleShortDtos(setArticleShortDtos, page, setTotalPages, props.searchValue);
    }, [page]);

    useEffect(() => {
        if (props.refreshArticles === true) {
            fetchArticleShortDtos(setArticleShortDtos, page, setTotalPages, props.searchValue);
            props.setRefreshArticles(false);
        }
    }, [props.refreshArticles]);

    return <Box
        sx={{
            margin: '10px',
            maxWidth: 'xl',
            width: '100%',
        }}
    >
        <HomeHeader />
        {articleShortDtos.map((articleShortDto, index) => {
        return (
            <ArticleShort
                key={articleShortDto.id}
                articleShortDto={articleShortDto}
                index={index}
                setRefreshArticles={props.setRefreshArticles}
            />
            )
        })}
        <Pagination
            count={totalPages}
            page={page+1}
            onChange={handleChange}
            variant="outlined"
            sx={{marginTop: '10px'}}
        />
    </Box>
}

export default ArticleList;