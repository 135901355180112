import React, {useEffect} from "react";
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Toolbar,
    Typography
} from "@mui/material";
import HiveIcon from '@mui/icons-material/Hive';
import {Facebook} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {useNavigate} from "react-router-dom";
import SearchNavbar from "./SearchNavbar";
import MobileNavbar from "./MobileNavbar";
import useToken from "../Auth/Token";

const pages = [
    'Artykuły',
    // 'Ogłoszenia',
    'Galeria',
    'Informacje'
];

const Navbar = (props: {searchValue: string | null, setSearchValue: (value: string) => void, refreshArticles: boolean, setRefreshArticles: (value: boolean) => void}) => {

    const navigate = useNavigate();
    const {token, removeToken} = useToken();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleFaceBookClick = () => {
        window.open("https://www.facebook.com/profile.php?id=100045807290182", "_blank");
    };

    const [isDrawerOpen, setDrawerOpen] = React.useState(false);
    const toggleDrawer = () => {
        setDrawerOpen(false);
    };
    const openDrawer = () => {
        setDrawerOpen(true);
    };

    const handleLogout = () => {
        removeToken();
        navigate('/');
    }

    const handleNavPageClick = (page: string) => {
        if (page === 'Artykuły') {
            navigate('/');
        } else if (page === 'Ogłoszenia') {
            navigate('/posts');
        } else if (page === 'Galeria') {
            navigate('/gallery');
        } else if (page === 'Informacje') {
            navigate('/info');
        } else {
            navigate('/'+page.toLowerCase());
        }
        props.setSearchValue("");
        props.setRefreshArticles(true);
    };

    return (
        <AppBar position="static" sx={{marginBottom: '15px', backgroundColor: '#ffab00'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <HiveIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            // fontFamily: "monospace",
                            fontWeight: 'bold',
                            // letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        eKurpik
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={openDrawer}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <MobileNavbar
                            open={isDrawerOpen}
                            handleFaceBookClick={handleFaceBookClick}
                            toggleDrawerHandler={toggleDrawer}
                            handleNavPageClick={handleNavPageClick}
                            pages={pages}
                            handleLogout={handleLogout}
                            token={token}
                            searchValue={props.searchValue}
                            setSearchValue={props.setSearchValue}
                            refreshArticles={props.refreshArticles}
                            setRefreshArticles={props.setRefreshArticles}
                        />
                    </Box>
                    <HiveIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            // fontFamily: "monospace",
                            fontWeight: 'bold',
                            // letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        eKurpik
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleNavPageClick(page)}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                {page}
                            </Button>
                        ))}
                        {token &&
                            <>
                                <Button
                                    key="newArticle"
                                    onClick={() => navigate('/article/new')}
                                    sx={{ my: 2, color: "white", display: "block", marginRight: '10px' }}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    Nowy artykuł
                                </Button>
                                <Button
                                    key="ipBlackList"
                                    onClick={() => navigate('/ip/blacklist')}
                                    sx={{ my: 2, color: "white", display: "block", marginRight: '10px' }}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    IP black lista
                                </Button>
                                <Button
                                    key="logout"
                                    onClick={handleLogout}
                                    sx={{ my: 2, color: "white", display: "block" }}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    Wyloguj
                                </Button>
                            </>
                        }
                        <Button
                            key='facebook'
                            onClick={handleFaceBookClick}
                            sx={{ my: 2, color: "white", display: "block" }}
                            >
                            <Facebook />
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <SearchNavbar
                            searchValue={props.searchValue}
                            setSearchValue={props.setSearchValue}
                            refreshArticles={props.refreshArticles}
                            setRefreshArticles={props.setRefreshArticles}
                        />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;