import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {fetchDocumentDto} from "./api";
import {styled} from "@mui/material/styles";
import {DocumentDto} from "./types";

const PdfContainer = styled('div')({
    position: 'relative',
    width: '95%',
    overflow: 'hidden',
    paddingTop: '100%', /* Ustawienie wysokości proporcjonalnej w stosunku do szerokości */
    maxWidth: '1536px',
});

const EmbedPdf = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width:'100%',
  height: '100%',
})

const DocumentPdf = () => {

    const [doc, setDoc] = useState<DocumentDto>();
    const params = useParams();

    useEffect(() => {
        if (params.id != null) {
            fetchDocumentDto(params.id, setDoc);
        }
    }, []);

    return (
            <PdfContainer>
                {doc && doc.data &&
                    <EmbedPdf
                        src={`data:application/pdf;headers=filename%3D${doc?.name};base64,${doc?.data}`}
                        title={doc?.name}
                    >{doc?.name}</EmbedPdf>
                }
        </PdfContainer>
    )
}

export default DocumentPdf;