import React, {useEffect, useState} from "react";
import {
    Box, Button, FormHelperText,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckIcon from '@mui/icons-material/Check';
// import DatePicker from "@mui/lab/DatePicker"
import {IpBlackListDto, NewBlockedIp} from "../types";
import {fetchIpBlackList, fetchNewIpBlocked, fetchUpdateIpBlocked} from "../api";
import useToken from "../../../Auth/Token";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {
    DateValidationError,
    LocalizationProvider,
    PickerChangeHandlerContext,
    PickerValidDate
} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {useFormik} from "formik";
import {newIpBlockedValidation} from "../validation";
import 'dayjs/locale/pl';
import {Send as SendIcon} from "@mui/icons-material";

const IpBlackList = () => {

    const [ipBlackList, setIpBlackList] = useState<IpBlackListDto[]>([]);
    const {token} = useToken();
    const [error, setError] = useState<string | null>(null);
    const [reload, setReload] = useState<boolean>(false);

    const updateBlockDate = (ipBlackListDto: IpBlackListDto) => {
        console.log("ipBlackListDto: ", ipBlackListDto)
        fetchUpdateIpBlocked(ipBlackListDto);
    }

    const getIpBlackList = () => {
        fetchIpBlackList(setIpBlackList);
    }

    useEffect(() => {
        getIpBlackList();
        setReload(false);
    }, [reload]);

    const formik = useFormik({
            initialValues: {
                ipAddress: '',
                blockDate: '',
            },
            validationSchema: newIpBlockedValidation,
            onSubmit: values => {
                console.log("Eeeeoooo");
                console.log(values);
                const newBlockedIp: NewBlockedIp = {
                    ipAddress: values.ipAddress.trim(),
                    blockDate: values.blockDate,
                };
                console.log("newBlockedIp: ", newBlockedIp)
                fetchNewIpBlocked(newBlockedIp, setReload, setError);
                formik.resetForm();
            }
        }
    )

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: '10px',
                width: '100%',
                maxWidth: 'xl',
            }}
        >
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>IP adress</TableCell>
                            <TableCell align="right">Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ipBlackList.map((ipBlocked) => (
                            <TableRow
                                key={ipBlocked.ipAddress}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {ipBlocked.ipAddress}
                                </TableCell>
                                <TableCell align="right">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                                        <DatePicker
                                            value={dayjs(ipBlocked.date)}
                                            onAccept={(date: Dayjs | null) => {
                                                ipBlocked.date = dayjs(date).format("YYYY-MM-DD HH:mm:ss.SSS");
                                                updateBlockDate(ipBlocked);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box margin="normal">
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        id="ipAddress"
                        name="ipAddress"
                        type="text"
                        label="Adres IP"
                        variant="standard"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ipAddress}
                        margin="normal"
                        error={(formik.errors.ipAddress && formik.touched.ipAddress) ? true : false}
                        helperText={(formik.errors.ipAddress && formik.touched.ipAddress) ? formik.errors.ipAddress : null}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                        <DatePicker
                            value={dayjs(formik.values.blockDate)}
                            onAccept={(date: Dayjs | null) => {
                                formik.values.blockDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss.SSS");
                                console.log(formik.values);
                            }}
                        />
                    </LocalizationProvider>
                    { error !== null && <FormHelperText error={true}>Nie możesz dodać zablokowanego ip.</FormHelperText>}
                    <Button type="submit" variant="outlined" sx={{ marginTop: '10px' }} endIcon={<SendIcon />}>
                        Dodaj
                    </Button>
                </form>
            </Box>
        </Box>
)
}

export default IpBlackList;