import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#ff3547',
        }
    },
    typography: {
        allVariants: {
            fontFamily: [
                'Roboto',
                'Ubuntu',
                'Helvetica',
                'Arial',
                'sans-serif'
            ].join(','),
        },
    }
});

export default theme;