import {useEffect, useState} from "react";

const useToken = () => {
    const getToken = () => {
        return sessionStorage.getItem('access_token');
    }

    const [token, setToken] = useState(getToken());
    const saveToken = (userToken: string) => {
        sessionStorage.setItem('access_token', userToken);
        setToken(userToken);
    }
    const removeToken = () => {
        sessionStorage.removeItem('access_token');
        setToken('');
    }
    return {
        removeToken: removeToken,
        setToken: saveToken,
        token
    }
};

export default useToken;