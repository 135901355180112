import React, {useEffect, useRef, useState} from "react";
import {UpdateArticleDto} from "../types";
import ReactQuill from "react-quill";
import EditorToolbar, {modules, formats} from "./EditorToolbar";
import {Field, FormikProvider, useFormik} from 'formik';

import "./../styles.css";
import {Box, Button, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {ArticleDto} from "../../../Article/types";
import {fetchEditArticleDto, fetchNewDocument} from "../api";
import {newArticleValidation} from "../validation";
import {backendUrl} from "../../../Global/globalValues";
import UploadDocument from "./UploadDocument";


const EditArticle = () => {
    const [thumbnailPreview, setThumbnailPreview] = useState<string | ArrayBuffer | null>(null);
    const [documents, setDocuments] = useState<File[]>([]);
    const params = useParams();
    const navigate = useNavigate();

    const [article, setArticle] = useState<ArticleDto>();

    const addDocument = (newDocuments: File[]) => {
        newDocuments.forEach((newDocument: File) => {
            setDocuments([...documents, newDocument]);
        })
    };

    const removeDocument = (documentToRemove: File) => {
        setDocuments(documents.filter(d => d !== documentToRemove));
        // fetchRemoveDocument(documentToRemove);
    }

    const removeThumbnail = () => {
        formik.setFieldValue('thumbnail', null);
        setThumbnailPreview('data:image/jpeg;base64,null');
    }

    const fetchArticle = (articleId: string | undefined) => {
        fetch(backendUrl + `/article/${articleId}`)
            .then(response => response.json())
            .then(data => {
                setArticle(data);
                setDocuments(data.documents);
                setThumbnailPreview('data:image/jpeg;base64,' + data.thumbnail);
            });
    }

    const fetchDocuments = (articleId: string | undefined) => {
        fetch(backendUrl + `/admin/document/article/${articleId}`)
            .then(response => response.json())
            .then(data => {
                setDocuments(data);
            });
    }

    useEffect(() => {
        fetchArticle(params.id);
        // fetchDocuments(params.id);
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: article?.id,
            title: article?.title,
            author: article?.author,
            content: article?.content ?? '',
            shortContent: article?.shortContent,
            thumbnail: article?.thumbnail,
            documents: article?.documents.map(doc => doc.id),
            // documents: documents,
        },
        validationSchema: newArticleValidation,
        onSubmit: async values => {
            const editAdrticleData: UpdateArticleDto = {
                id: article?.id,
                title: values.title,
                author: values.author,
                content: values.content,
                shortContent: values.shortContent,
                thumbnail: values.thumbnail,
                documents: values.documents
            }
            if (editAdrticleData !== undefined) {
                const newDocumentsIds: string[] = [];
                for (const doc of documents) {
                    const docId = await fetchNewDocument(doc);
                    newDocumentsIds.push(docId!);
                }
                fetchEditArticleDto(editAdrticleData, params.id);
                navigate('/');
            }
        },
    });

    // console.log('thumbnailPreview: ', thumbnailPreview);
    // console.log('startWith: ', typeof thumbnailPreview === 'string' && thumbnailPreview?.startsWith('data:image/jpeg;base64'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: '10px',
                maxWidth: 'xl',
                // '& .MuiTextField-root': {width: '25ch'},
            }}
        >
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <h1> Dodaj nowy artykuł </h1>
                    <Box>
                        <TextField
                            id="title"
                            label={formik.values.title ? "" : "Tytuł"}
                            variant="standard"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            multiline
                            fullWidth
                            margin={"normal"}
                            error={(formik.errors.title && formik.touched.title) ? true : false}
                            helperText={(formik.errors.title && formik.touched.title) ? formik.errors.title : null}
                        />
                        <TextField
                            id="author"
                            label={formik.values.author ? "" : "Autor"}
                            variant="standard"
                            value={formik.values.author}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            error={(formik.errors.author && formik.touched.author) ? true : false}
                            helperText={(formik.errors.author && formik.touched.author) ? formik.errors.author : null}
                        />
                        {thumbnailPreview && typeof thumbnailPreview === 'string' && !thumbnailPreview.startsWith('data:image/jpeg;base64,null') && <img src={thumbnailPreview} alt="<miniaturka>" width="100"/>}
                        <input
                            accept="image/*"
                            style={{display: 'none'}}
                            id="thumbnail"
                            type="file"
                            onChange={(event) => {
                                debugger;
                                formik.setFieldValue("thumbnail", event.currentTarget.files?.item(0)?.arrayBuffer());
                                const fileReader = new FileReader();
                                fileReader.onload = () => {
                                    if (fileReader.readyState === 2) {
                                        formik.setFieldValue('thumbnail', fileReader.result);
                                        setThumbnailPreview(fileReader.result);
                                    }
                                };
                                fileReader.readAsDataURL(event.target.files?.item(0) as Blob);
                            }}
                        />
                        <label htmlFor="thumbnail">
                            <Button
                                variant="text"
                                component="span"
                            >
                                Dodaj miniaturkę
                            </Button>
                        </label>
                        <Button
                            onClick={() => removeThumbnail()}
                        >
                            Usuń miniaturkę
                        </Button>
                        <TextField
                            id="shortContent"
                            label={formik.values.shortContent ? "" : "Krótki opis"}
                            variant="standard"
                            value={formik.values.shortContent}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            multiline
                            fullWidth
                            error={(formik.errors.shortContent && formik.touched.shortContent) ? true : false}
                            helperText={(formik.errors.shortContent && formik.touched.shortContent) ? formik.errors.shortContent : null}
                        />
                        <div className="text-editor">
                            <EditorToolbar/>
                            <Field name="content">
                                {({field}: { field: { value: string, name: string, onChange: any, onBlur: any } }) =>
                                    <ReactQuill
                                        theme="snow"
                                        value={field.value}
                                        onChange={field.onChange(field.name)}
                                        onBlur={() => formik.setTouched({content: true})}
                                        placeholder={field.value}
                                        modules={modules}
                                        formats={formats}
                                    />
                                }
                            </Field>
                        </div>
                        {formik.errors.content && formik.touched.content ? (
                            <div>{formik.errors.content}</div>
                        ) : null}
                        <a>Długość tekstu: {formik.values.content.length}/50000</a><br />
                        <UploadDocument
                            documents={documents}
                            addDocuments={addDocument}
                            removeDocument={removeDocument}
                        />
                        <Button type="submit">Edytuj artykuł</Button>
                    </Box>
                </form>
            </FormikProvider>

            <hr/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '10px',
                    maxWidth: 'xl',
                    // '& .MuiTextField-root': {width: '25ch'},
                }}>
                <h1> Podgląd </h1>
                <div dangerouslySetInnerHTML={{__html: formik.values.content.replace(/(<? *script)/gi, 'illegalscript')}}>
                </div>
                <hr/>
                {/*<a>*/}
                {/*    {formik.values.content}*/}
                {/*</a>*/}
            </Box>

        </Box>
    );
}

export default EditArticle;