import React from "react";
import {Box, Container, CssBaseline, Avatar, Typography, Grid, TextField, Button, Link} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {RegisterDto} from "../types";
import axios, {AxiosResponse} from "axios";
import {backendUrl} from "../../../Global/globalValues";
import {useLocation, useNavigate} from "react-router-dom";
import {FormikProvider, useFormik} from "formik";

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const fetchRegister = (registerDto: RegisterDto) => {
        axios.post<Response>(backendUrl + "/user",
            registerDto,
            {
                withCredentials: true
            }
        ).then((res: AxiosResponse) => {
            if (res.status >= 401) {
                alert(res.status + ": " + res.data);
            } else if (res.status === 200) {
                navigate(from, {replace: true});
            }
            return res.status;
        });
    }

    const formik = useFormik({
            initialValues: {
                username: '',
                email: '',
                password: '',
                confirmPassword: '',
            },
            onSubmit: values => {
                const registerDto: RegisterDto = {
                    username: values.username,
                    email: values.email,
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                };
                if (registerDto !== undefined) {
                    fetchRegister(registerDto);
                }
                alert(JSON.stringify(values, null, 2));
            }
        }
    )

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{
                    m: 1,
                    bgcolor: 'secondary.main',
                }}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Rejestracja
                </Typography>
                <FormikProvider value={formik}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="username"
                                    name="username"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Nazwa użytkownika"
                                    autoFocus
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Adres e-mail"
                                    name="email"
                                    autoComplete="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Hasło"
                                    type="password"
                                    id="password"
                                    autoComplete="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Potwierdź hasło"
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Zarejestruj się
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href={"/login"} variant="body2">
                                    Masz już konto? Zaloguj się
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </FormikProvider>
            </Box>
        </Container>
    );
}

export default Register;