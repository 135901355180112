import React, {FormEvent, useEffect, useState} from "react";
import {alpha, Box, InputBase, styled} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#f5f5f5', 0.15),
    '&:hover': {
        backgroundColor: alpha('#eeeeee', 0.15),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const SearchNavbar = (props: {searchValue: string | null, setSearchValue: (value: string) => void, refreshArticles: boolean, setRefreshArticles: (value: boolean) => void}) => {

    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(() => {
        setSearchValue("");
    }, [props.refreshArticles]);

    const handleSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.setSearchValue(searchValue);
        props.setRefreshArticles(true);
    }
    return (
        <Box component="form" onSubmit={handleSearch}>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Szukaj…"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(event) => {setSearchValue(event.target.value)}}
                    value={searchValue}
                />
            </Search>
        </Box>
    );
}

export default SearchNavbar;