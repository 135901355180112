import React, {useEffect, useRef, useState} from "react";
import {Box, Button, FormHelperText, TextField} from "@mui/material";
import axios, {AxiosResponse} from "axios";
import {backendUrl} from "../../../Global/globalValues";
import {useFormik} from "formik";
import {NewCommentDto} from "./types";
import {Send as SendIcon} from "@mui/icons-material";
import {newCommentValidation} from "./validation";
import {Turnstile, TurnstileInstance} from "@marsidev/react-turnstile";

type Status = 'error' | 'expired' | 'solved'

const NewComment = (props: {articleId: string | undefined, setReload: (reload: boolean) => void, rootCommentId?: string | null, setOpenReplyForm?: (openReplyComentId: string) => void}) => {

    const [ipAddress, setIPAddress] = useState('');
    const ref = useRef<TurnstileInstance>(null);
    const [status, setStatus] = React.useState<Status | null>(null);
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIPAddress(data.ip))
            .catch(error => console.log(error))
    }, []);

    const fetchNewComment = (newCommentDto: NewCommentDto) => {
        axios.post<Response>(backendUrl + "/article/"+props.articleId+"/comment",
            newCommentDto,
            {
                withCredentials: false
            }
        ).then((res: AxiosResponse) => {
            if (res.status === 403) {
                setError(res.data.message);
            } else if (res.status >= 401) {
                alert(res.status + ": " + res.data);
            } else if (res.status === 201) {
                props.setReload(true);
                props.setOpenReplyForm && props.setOpenReplyForm('');
                setError(null);
            }
            ref.current?.reset();
            return res.status;
        }).catch(err => {
            if (err.response?.status === 403) {
                setError(err.response?.status);
            }
            // console.log(err);
        });
    }

    const formik = useFormik({
            initialValues: {
                author: '',
                message: '',
                articleId: props.articleId,
                rootCommentId: props.rootCommentId,
                ipAddress: '',
                validationToken: '',
            },
        validationSchema: newCommentValidation,
            onSubmit: values => {
                const newCommentDto: NewCommentDto = {
                    author: values.author.trim(),
                    message: values.message.trim(),
                    articleId: props.articleId,
                    rootCommentId: props.rootCommentId? props.rootCommentId : null,
                    ipAddress: ipAddress,
                    validationToken: values.validationToken,
                };
                if (newCommentDto !== undefined && status === 'solved') {
                    fetchNewComment(newCommentDto);
                }
                formik.resetForm();
            }
        }
    )

    return (
        <Box >
            {props.rootCommentId == null && <h3>Dodaj komentarz</h3>}
            {props.rootCommentId != null && <h3>Odpowiedz na komentarz</h3>}
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    id="author"
                    name="author"
                    type="text"
                    label="Nazwa autora"
                    variant="standard"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.author}
                    margin="normal"
                    error={(formik.errors.author && formik.touched.author) ? true : false}
                    helperText={(formik.errors.author && formik.touched.author) ? formik.errors.author : null}
                />
                <TextField
                    id="message"
                    name="message"
                    type="text"
                    label="Treść komentarza"
                    variant="standard"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    multiline
                    rows={4}
                    fullWidth
                    margin="normal"
                    error={(formik.errors.message && formik.touched.message) ? true : false}
                    helperText={(formik.errors.message && formik.touched.message) ? formik.errors.message : null}
                />
                <Turnstile
                    ref={ref}
                    // options={{ refreshExpired: 'manual' }}
                    siteKey='0x4AAAAAAAgAeKU9lwCJt5M9'
                    options={{
                        action: 'submit-form',
                        theme: 'light',
                        language: 'pl',
                    }}
                    onError={() => setStatus('error')}
                    onExpire={() => setStatus('expired')}
                    onSuccess={(token) => {
                        setStatus('solved');
                        formik.setFieldValue("validationToken", token);
                    }}
                    // onExpire={() => ref.current?.reset()}
                />
                { error !== null && <FormHelperText error={true}>Nie możesz dodać komentarza.</FormHelperText>}
                {(status === 'error' || status === 'expired') && <FormHelperText error={true}>Chyba nie jesteś człowiekiem.</FormHelperText> }
                <Button type="submit" variant="outlined" sx={{ marginTop: '10px' }} endIcon={<SendIcon />}>
                    Dodaj
                </Button>
            </form>
        </Box>
    );
}

export default NewComment;