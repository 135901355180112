import {AxiosResponse} from "axios";
import {axiosWithAuth} from "../../Auth/axiosWithToken";
import {IpBlackListDto, IpBlackListResponse, NewBlockedIp} from "./types";
import {backendUrl} from "../../Global/globalValues";
import {useNavigate} from "react-router-dom";

export const fetchIpBlackList = (setIpBlackList: any) => {
    axiosWithAuth().get<IpBlackListResponse>("/ip/blacklist").then((res: AxiosResponse) => {
        setIpBlackList(res.data);
    }).catch(err => {
        if(err.status === 403) {
            const navigate = useNavigate();
            navigate("/login");
        }
    })
}

export const fetchUpdateIpBlocked = (ipBlackListDto: IpBlackListDto) => {
    axiosWithAuth().put<IpBlackListResponse>(backendUrl + "/ip/blacklist",
        ipBlackListDto,
        {
            withCredentials: true
        })
        .then((res: AxiosResponse) => {
            console.log("updated date");
    }).catch(err => {
        if(err.status === 403) {
            const navigate = useNavigate();
            navigate("/login");
        }
    })
}

export const fetchNewIpBlocked = (newIpBlocked: NewBlockedIp, setReload: (reload: boolean) => void, setError: (error: string | null) => void) => {
    axiosWithAuth().post<IpBlackListResponse>(backendUrl + "/ip/blacklist",
            newIpBlocked,
        {
            withCredentials: true
        }
        ).then((res: AxiosResponse) => {
        if (res.status === 403) {
            setError(res.data.message);
        } else if (res.status >= 401) {
            alert(res.status + ": " + res.data);
        } else if (res.status === 201) {
            setReload(true);
            setError(null);
        }
        return res.status;
        }).catch(err => {
        if (err.response?.status === 403) {
            setError(err.response?.status);
        }
    })
}