import React from "react";
import {
    Box, Button,
    Drawer,
    List,
    ListItemButton,
    ListItemText,
    styled,
    Typography
} from "@mui/material";
import {Facebook as FacebookIcon} from "@mui/icons-material";
import SearchNavbar from "./SearchNavbar";
import HiveIcon from "@mui/icons-material/Hive";
import {useNavigate} from "react-router-dom";

interface MobileNavbarProps {
    toggleDrawerHandler: () => void;
    handleFaceBookClick: () => void;
    handleNavPageClick: (page: string) => void;
    open: boolean;
    pages: string[];
    handleLogout: () => void;
    token: string | null;
    searchValue: string | null;
    setSearchValue: (value: string) => void;
    refreshArticles: boolean;
    setRefreshArticles: (value: boolean) => void;
}

const DrawerMenu = styled('div')(({theme}) => ({
    backgroundColor: '#ffab00',
    width: '250',
    padding: '12px',
    color: 'white',
    height: '100%',
}));

const MobileNavbar: React.FC<MobileNavbarProps> = ({
   toggleDrawerHandler,
   handleFaceBookClick,
   handleNavPageClick,
   open,
   pages,
   handleLogout,
   token,
    searchValue,
    setSearchValue,
    refreshArticles,
    setRefreshArticles,
}) => {

    const navigate = useNavigate();

    const handleMobileNavPageClick = (page: string) => {
        handleNavPageClick(page);
        toggleDrawerHandler();
    }

    const handleMobileLogout = () => {
        handleLogout();
        toggleDrawerHandler();
    }

    const handleMobileNewArticle = () => {
        navigate('/article/new');
        toggleDrawerHandler();
    }

    return (
        <Drawer open={open} onClose={toggleDrawerHandler}>
            <DrawerMenu
                // className="mobile-menu-list"
                role="presentation"
                // onClick={toggleDrawerHandler}
                // onKeyDown={toggleDrawerHandler}
            >
                <List>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }} >
                        <HiveIcon sx={{ mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            // href="/"
                            onClick={() => handleNavPageClick("")}
                            sx={{
                                mr: 2,
                                // display: { xs: "none", md: "flex" },
                                // fontFamily: "monospace",
                                fontWeight: 'bold',
                                // letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            eKurpik
                        </Typography>
                    </Box>
                    {pages.map((page: string) => (
                        <ListItemButton key={page} onClick={() => handleMobileNavPageClick(page)}>
                            <ListItemText primary={page} sx={{color: "white"}} />
                        </ListItemButton>
                    ))}
                    {token &&
                        <>
                            <Button
                                key="newArticle"
                                onClick={() => handleMobileNewArticle()}
                                sx={{ my: 2, color: "white", display: "block" }}
                                variant="outlined"
                                color="inherit"
                            >
                                Nowy artykuł
                            </Button>
                            <Button
                                key="logout"
                                onClick={() => handleMobileLogout()}
                                sx={{ my: 2, color: "white", display: "block" }}
                                variant="outlined"
                                color="inherit"
                            >
                                Wyloguj
                            </Button>
                        </>
                    }
                    <Button
                        key='facebook'
                        onClick={handleFaceBookClick}
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        <FacebookIcon />
                    </Button>
                    <SearchNavbar
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        refreshArticles={refreshArticles}
                        setRefreshArticles={setRefreshArticles}
                    />
                </List>
            </DrawerMenu>
        </Drawer>
    );
};

export default MobileNavbar;