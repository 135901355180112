import React from "react";
import {styled} from "@mui/material/styles";

const InfoDiv = styled('div')(({}) => ({
    margin: '50px',
    textAlign: 'left',
}))

const Information = () => {
    return (
        <InfoDiv>
            <h1>Regulamin Strony eKurpik</h1>

            <p><strong>1. Komentarze:</strong></p>
            <ul>
                <li>Użytkownicy mogą komentować artykuły bez konieczności rejestracji ani logowania.</li>
                <li>Komentarze muszą być związane z treścią artykułu i konstruktywne.</li>
                <li>Nieakceptowalne są obraźliwe, dyskryminujące lub nieodpowiednie komentarze. Szanuj innych użytkowników.</li>
            </ul>

            <p><strong>2. Moderacja Komentarzy:</strong></p>
            <ul>
                <li>Administracja ma prawo usuwać lub edytować komentarze, które łamią regulamin.</li>
                <li>Komentarze mogą być oznaczane przez innych użytkowników jako nieodpowiednie.</li>
            </ul>

            <p><strong>3. Linki i Spam:</strong></p>
            <ul>
                <li>Nie wolno dodawać komentarzy zawierających spam, reklamy lub linki do stron trzecich bez zgody administratora.</li>
            </ul>

            <p><strong>4. Prywatność:</strong></p>
            <ul>
                <li>Nie wolno ujawniać prywatnych danych innych użytkowników ani próbować pozyskiwać takich danych od innych użytkowników.</li>
            </ul>

            <p><strong>5. Kontakt z Administracją:</strong></p>
            <ul>
                <li>Użytkownicy mogą skontaktować się z administracją za pomocą konta na Facebooku pod adresem <a href="https://www.facebook.com/people/Kurpik/100045807290182/">[link do Facebooka]</a> lub za pomocą adresu e-mail <a href="mailto: ekurpik.pl@gmail.com">ekurpik.pl@gmail.com</a>.</li>
            </ul>

            <p><strong>6. Zasada "Netiquette":</strong></p>
            <ul>
                <li>Użytkownicy proszeni są o przestrzeganie ogólnych zasad kultury internetowej i szacunku wobec innych uczestników.</li>
            </ul>

            <p><strong>7. Zmiany w Regulaminie:</strong></p>
            <ul>
                <li>Administracja zastrzega sobie prawo do zmiany regulaminu w dowolnym momencie.</li>
            </ul>

            <p>Dziękujemy za przestrzeganie zasad i tworzenie konstruktywnej społeczności na naszej stronie z artykułami i komentarzami.</p>
        </InfoDiv>
    );
}

export default Information;