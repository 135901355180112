import * as Yup from 'yup';

const forbiddenWords = ['porn', 'nude', 'Cryptocurrency', '[url=http://', '<a href="http://'];
export const newCommentValidation = Yup.object().shape({
    message: Yup.string()
        .min(3, 'Za krótki komentarz')
        .max(2047, 'Za długi komentarz')
        .matches(
            /^[A-Za-z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż\s,.;:!?()\-'"`]*$/,
            'Tekst zawiera niedozwolone znaki.'
        )
        .test('contains-forbidden-words', 'Tekst zawiera zakazane słowo', (value) => {
            if (!value) return true;
            const words = value.split(/\s+/);
            return !words.some((word) => forbiddenWords.includes(word));
        })
        .required('Komentarz nie może być pusty'),
    author: Yup.string()
        .min(3, 'Za krótka nazwa autora')
        .max(63, 'Za długa nazwa autora')
        .matches(
            /^[A-Za-z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż\s,.;:!?()\-'"`]*$/,
            'Nazwa zawiera niedozwolone znaki.'
        )
        .required('Autor nie może być pusty'),
});