import * as Yup from 'yup';

export const newArticleValidation = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Za krótki tytuł')
        .max(512, 'Za długi tytuł')
        .required('Tytuł nie może być pusty'),
    content: Yup.string()
        .min(2, 'Za krótki artykuł')
        .max(50000, 'Za długi artykuł')
        .required('Artykuł nie może być pusty'),
    shortContent: Yup.string()
        .min(2, 'Za krótki opis')
        .max(1000, 'Za długi opis')
        .required('Opis nie może być pusty'),
    author: Yup.string()
        .min(2, 'Za krótka nazwa autora')
        .max(256, 'Za długa nazwa autora')
        .required('Autor nie może być pusty'),
});