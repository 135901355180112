import React from "react";
import {styled} from "@mui/material/styles";
import {Button, Container, Typography} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const DocumentListElement = styled('li')({
    width: '80%',
    textAlign: 'left',
})
const UploadDocument = (props: {addDocuments: (documents: File[]) => void, documents: File[], removeDocument: (document: File) => void}) => {

    const uploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            const files = Array.from(selectedFiles);
            props.addDocuments(files);
        }
    }

    const removeDocument = (doc: File) => {
        props.removeDocument(doc);
    }

    // const uploadFiles2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     props.setDocuments(e.currentTarget.files?.item(0)?.arrayBuffer());
    //     const fileReader = new FileReader();
    //     fileReader.onload = () => {
    //         if (fileReader.readyState === 2) {
    //             props.setDocuments(fileReader.result);
    //         }
    //     };
    //     fileReader.readAsDataURL(e.target.files?.item(0) as Blob);
    // }

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="h5">Dokumenty:</Typography>
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                Upload file
                <VisuallyHiddenInput
                    accept="application/pdf"
                    type="file"
                    multiple
                    onChange={uploadFiles}
                />
            </Button>
                {/*<Field name="documents" type="file" multiple onChange={uploadFiles} />*/}
            <ul>
                {props.documents.map((document: File) => {
                    return (
                        <span>
                        <DocumentListElement key={document.name}>
                            {document.name}
                            <Button
                                key={"delete-"+document?.name}
                                onClick={() => removeDocument(document)}
                            >
                                Usuń
                            </Button>
                        </DocumentListElement>
                        </span>
                    );
                })}
            </ul>
        </Container>
    );
}

export default UploadDocument;

