import React from 'react';
import {LoginDto} from "../types";
import {FormikProvider, useFormik} from "formik";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel, Grid, Link,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useLocation, useNavigate} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import {backendUrl} from "../../../Global/globalValues";
import useToken from "../../../Auth/Token";
import useRefreshToken from "../../../Auth/RefreshToken";



const Login = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {token, setToken} = useToken();
    const {refreshToken, setRefreshToken} = useRefreshToken();
    const from = location.state?.from?.pathname || "/";

    const fetchLogin = (signinDto: LoginDto) => {
        axios.post<Response>(backendUrl + "/login",
            signinDto,
            {
                withCredentials: true
            }
        ).then((res: AxiosResponse) => {
            if (res.status >= 401) {
                alert(res.status + ": " + res.data);

            } else if (res.status === 200) {
                setToken(res.data.access_token);
                setRefreshToken(res.data.refresh_token);
                navigate(from, {replace: true});
            }
            return res.status;
        });

    }

    const formik = useFormik({
            initialValues: {
                username: '',
                password: '',
            },
            onSubmit: values => {
                const loginDto: LoginDto = {
                    username: values.username,
                    password: values.password,
                };
                if (loginDto !== undefined) {
                    fetchLogin(loginDto);
                }
            }
        }
    )

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItem: 'center',
                }}
            >
                <Avatar sx={{
                    m: 1,
                    bgcolor: 'secondary.main',
                }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Logowanie
                </Typography>
                <FormikProvider value={formik}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Nazwa użytkownika"
                            name="username"
                            autoComplete="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            autoFocus
                            />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Hasło"
                            name="password"
                            type="password"
                            autoComplete="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Zapamiętaj mnie"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Zapomniałeś/łaś hasła?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    Nie masz konta? Zarejestruj się
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </FormikProvider>
            </Box>
        </Container>
    )
}

export default Login;