import * as Yup from 'yup';

export const newIpBlockedValidation = Yup.object().shape({
    ipAddress: Yup.string()
        .min(7, '')
        .max(15, '')
        .matches(
        /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/,
            'Błedny format IP'
        )
        .required('Adres IP nie może być pusty'),
    // blockDate: Yup.string()
    //     .matches(
    //         /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2}):(\d{3,6})$/,
    //         'Błędny format daty'
    //     )
    //     .required(),
});