import axios from "axios";
import {backendUrl} from "../Global/globalValues";
import {useNavigate} from "react-router-dom";

export const axiosWithAuth = () => {
    const token = sessionStorage.getItem('access_token');

    axios.interceptors.response.use(
        response => response,
        error => {
            const {status} = error.response;
            if (status === 401) {
                sessionStorage.removeItem('access_token');
                window.location.href = '/login';
            } else if(status === 403) {
                const navigate = useNavigate();
                navigate("/login");
            }
            return Promise.reject(error);
        }
    );

    return axios.create({
        baseURL : backendUrl,
        headers: {
            Authorization: 'Bearer ' + token,
            // Content-Type: "application/json",
            // timeout : 1000,
        },
// .. other options
    });
}

export const axiosFileWithAuth = () => {
    const token = sessionStorage.getItem('access_token');

    axios.interceptors.response.use(
        response => response,
        error => {
            const {status} = error.response;
            if (status === 401) {
                sessionStorage.removeItem('access_token');
                window.location.href = '/login';
            }
            return Promise.reject(error);
        }
    );

    return axios.create({
        baseURL : backendUrl,
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
            timeout : 1000,
        },
// .. other options
    });
}
