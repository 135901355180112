import {useState} from "react";

const useRefreshToken = () => {
    const getRefreshToken = () => {
        return sessionStorage.getItem('refreshToken');
    }

    const [refreshToken, setRefreshToken] = useState(getRefreshToken());
    const saveRefreshToken = (refreshToken: string) => {
        sessionStorage.setItem('refreshToken', refreshToken);
        setRefreshToken(refreshToken);
    }
    const removeRefreshToken = () => {
        sessionStorage.removeItem('refreshToken');
        setRefreshToken('');
    }
    return {
        removeRefreshToken: removeRefreshToken,
        setRefreshToken: saveRefreshToken,
        refreshToken
    }
};

export default useRefreshToken;