import React, {useEffect, useRef, useState} from "react";
import {fetchNewArticleDto, fetchNewDocument} from "../api";
import {NewArticleDto} from "../types";
import ReactQuill from "react-quill";
import EditorToolbar, {formats, modules} from "./EditorToolbar";
import {Field, FormikProvider, useFormik} from 'formik';

import "./../styles.css";
import {Box, Button, Divider, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {newArticleValidation} from "../validation";
import UploadDocument from "./UploadDocument";
import article from "../../../Article/Components/Article";


const NewArticle = () => {
    const [thumbnailPreview, setThumbnailPreview] = useState<string | ArrayBuffer | null>(null);
    const [documents, setDocuments] = useState<File[]>([]);
    const documentsIdsRef = useRef<string[]>([]);
    const [documentsIds, setDocumentsIds] = useState(documentsIdsRef.current);
    const navigate = useNavigate();

    const addDocument = (newDocuments: File[]) => {
        debugger;
        newDocuments.forEach((newDocument: File) => {
            setDocuments([...documents, newDocument]);
        })
    };

    const removeDocument = (documentToRemove: File) => {
        setDocuments(documents.filter(d => d !== documentToRemove))
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            author: '',
            content: '',
            shortContent: '',
            thumbnail: null,
            documents: [],
        },
        validationSchema: newArticleValidation,
        onSubmit: async values => {
            const newArticleData: NewArticleDto = {
                title: values.title,
                author: values.author,
                content: values.content,
                shortContent: values.shortContent,
                thumbnail: values.thumbnail,
                documents: values.documents
            }
            if (newArticleData !== undefined) {
                if (documents.length !== 0) {
                    const newDocumentsIds: string[] = [];
                    for (const doc of documents) {
                        const docId = await fetchNewDocument(doc);
                        newDocumentsIds.push(docId!);
                    }
                    newArticleData.documents = newDocumentsIds;
                }
                fetchNewArticleDto(newArticleData);
                navigate('/');
            }
        },
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: '10px',
                width: '100%',
                maxWidth: 'xl',
                // '& .MuiTextField-root': {width: '25ch'},
            }}
        >
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <h1> Dodaj nowy artykuł </h1>
                    <Box>
                        <TextField
                            id="title"
                            label="Tytuł"
                            variant="standard"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            multiline
                            fullWidth
                            error={(formik.errors.title && formik.touched.title) ? true : false}
                            helperText={(formik.errors.title && formik.touched.title) ? formik.errors.title : null}
                        />
                        <TextField
                            id="author"
                            label="Autor"
                            variant="standard"
                            value={formik.values.author}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            error={(formik.errors.author && formik.touched.author) ? true : false}
                            helperText={(formik.errors.author && formik.touched.author) ? formik.errors.author : null}
                        />
                        {thumbnailPreview && typeof thumbnailPreview === 'string' && <img src={thumbnailPreview} alt="miniaturka" width="100"/>}
                        <input
                            accept="image/*"
                            style={{display: 'none'}}
                            id="thumbnail"
                            type="file"
                            onChange={(event) => {
                                formik.setFieldValue("thumbnail", event.currentTarget.files?.item(0)?.arrayBuffer());
                                const fileReader = new FileReader();
                                fileReader.onload = () => {
                                    if (fileReader.readyState === 2) {
                                        formik.setFieldValue('thumbnail', fileReader.result);
                                        setThumbnailPreview(fileReader.result);
                                    }
                                };
                                fileReader.readAsDataURL(event.target.files?.item(0) as Blob);
                            }}
                        />
                        <label htmlFor="thumbnail">
                            <Button
                                variant="text"
                                component="span"
                            >
                                Dodaj miniaturkę
                            </Button>
                        </label>
                        <TextField
                            id="shortContent"
                            label="Krotki opis"
                            variant="standard"
                            value={formik.values.shortContent}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            multiline
                            fullWidth
                            error={(formik.errors.shortContent && formik.touched.shortContent) ? true : false}
                            helperText={(formik.errors.shortContent && formik.touched.shortContent) ? formik.errors.shortContent : null}
                        />
                        {formik.errors.shortContent && formik.touched.shortContent ? (
                            <div>{formik.errors.shortContent}</div>
                        ) : null}
                        <div className="text-editor">
                            <EditorToolbar/>
                            <Field name="content">
                                {({field}: { field: { value: string, name: string, onChange: any, onBlur: any } }) =>
                                    <ReactQuill
                                        theme="snow"
                                        value={field.value}
                                        onChange={field.onChange(field.name)}
                                        onBlur={() => formik.setTouched({content: true})}
                                        placeholder={"Dodaj treść artykułu..."}
                                        modules={modules}
                                        formats={formats}
                                    />
                                }
                            </Field>
                        </div>

                        {formik.errors.content && formik.touched.content ? (
                            <div>{formik.errors.content}</div>
                        ) : null}
                        <a>Długość tekstu: {formik.values.content.length}/50000</a><br />
                        <UploadDocument
                            documents={documents}
                            addDocuments={addDocument}
                            removeDocument={removeDocument}
                        />
                        <Button type="submit">Zapisz artykuł</Button>
                    </Box>
                </form>
            </FormikProvider>
            <Divider />

            <hr/>
            <h1> Podgląd </h1>
            <div dangerouslySetInnerHTML={{__html: formik.values.content.replace(/(<? *script)/gi, 'illegalscript')}}>
            </div>
            <hr/>
            <a>
                {formik.values.content}
            </a>
        </Box>
    );
}

export default NewArticle;