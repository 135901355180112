import {Navigate} from "react-router-dom";
import React from "react";
import useToken from "./Token";

const RequireAuth = (props: any) => {
    const {token} = useToken();
    if (props.isLogged) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        )
    }
    return <Navigate to="/login" replace />
}

export default RequireAuth;