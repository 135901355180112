import {ArticleShortDto} from "../types";
import React from "react";
import {Box, Button, Typography} from "@mui/material";
import "./../article.css";
import {useNavigate} from "react-router-dom";
import useToken from "../../Auth/Token";
import {fetchDeleteArticleDto} from "../../Admin/NewArticle/api";

const ArticleShort = (props: { articleShortDto: ArticleShortDto, index: number, setRefreshArticles: (value: boolean) => void }) => {
    const navigate = useNavigate();
    const {token} = useToken();

    const hadleClick = () => {
        navigate("/article/" + props.articleShortDto.id);
    }

    const hadleEdit = () => {
        navigate("/article/edit/" + props.articleShortDto.id);
    }

    const hadleDelete = () => {
        fetchDeleteArticleDto(props.articleShortDto.id);
        props.setRefreshArticles(true);
    }

    const getThumbnail = (thumbnail: string) => {
        if (thumbnail.startsWith("data:image/jpeg;base64,") || thumbnail.startsWith("data:image/png;base64,")) {
            return thumbnail;
        }
        return "data:image/jpeg;base64," + thumbnail;
    }

    return (
        <article className="articleShort">
            <Box key={props.index} className="articleItem">
                {props.articleShortDto.thumbnail &&
                    <img className="thumbnailShort" src={getThumbnail(props.articleShortDto.thumbnail)}
                         alt="miniaturka"/>
                }
                <h2 className="title">{props.articleShortDto.title}</h2>
                <Typography className="author">{props.articleShortDto.author}</Typography>
                <Typography className="creationDatetime"> {props.articleShortDto.creationDatetime}</Typography>
                <Typography className="content">{props.articleShortDto.shortContent}</Typography>
                <Box className="buttonBox">
                    {token &&
                        <>
                            <Button
                                className="articleButton"
                                variant="outlined"
                                sx={{marginRight: "10px"}}
                                onClick={hadleEdit}
                            >
                                Edytuj
                            </Button>
                            <Button
                                className="articleButton"
                                variant="outlined"
                                sx={{marginRight: "10px"}}
                                onClick={hadleDelete}
                            >
                                Usuń
                            </Button>
                        </>
                    }
                    <Button
                        className="articleButton"
                        variant="contained"
                        onClick={hadleClick}>
                        Czytaj dalej...
                    </Button>
                </Box>
            </Box>
        </article>
    )
}

export default ArticleShort;