import React from "react";
import {styled} from "@mui/material/styles";

const IframeDiv = styled('div')(({}) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%',
    margin: '20px',
}))

const Iframe = styled('iframe')(({}) => ({
    position: 'absolute',
    top: '0',
    left: '50%',
    bottom: '0',
    right: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
    height: '100%',
    maxWidth: '1100px',
    maxHeight: '624px',
}))

const Gallery = () => {
    return (
        <IframeDiv className="embed-responsive embed-responsive-16by9">
            <Iframe width="100%" height="auto" className="embed-responsive-item" src="https://www.youtube.com/embed/ONf3GE2Sk_4"></Iframe>
        </IframeDiv>
    );
}

export default Gallery;